import { Cart, Customer, LineItem, Order, Region } from "@medusajs/medusa";
import { PricedProduct, PricedVariant } from "@medusajs/medusa/dist/types/pricing";
import { sendGTMEvent } from "@next/third-parties/google";
import _ from "lodash";

import { ProductPreviewType } from "@/types/global";
import { CalculatedVariant } from "@/types/medusa";

type Product = {
  affiliation?: string;
  discount: number;
  index: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_id: string;
  item_list_id: string;
  item_list_name?: string;
  item_name: string;
  item_variant: string;
  price: number;
  quantity: number;
};

type ProductListView = {
  categoryPath?: string[];
  itemListId: string;
  itemListName?: string;
  items: ProductPreviewType[];
  eventBeforeSend?: ProductEventData;
  skipFirst?: number;
};

type ProductCardClick = {
  categoryPath?: string[];
  itemListId: string;
  itemListName?: string;
  items: ProductPreviewType[];
  index: number;
};

type GtmEventType = "view_item_list" | "select_item" | "view_item" | "addToCart" | "removeFromCart" | "checkout" | "purchase";

type BasicEvent = {
  event: GtmEventType;
};

type CartModifyProduct = {
  brand?: string;
  category?: string;
  dimension1: string | null;
  dimension2: string | null;
  dimension3: string | null;
  dimension7?: string;
  id: string;
  metric1: number;
  name: string;
  price: number;
  quantity: number;
  variant?: string;
};

type CheckoutStepProduct = {
  brand?: string;
  dimension1: string | null;
  dimension2: string | null;
  dimension3: string | null;
  id: string;
  metric1: number;
  name: string;
  price: number;
  quantity: number;
  variant?: string;
  category?: string;
};

export interface ProductEventData extends BasicEvent {
  ecommerce: {
    item_list_id: string;
    item_list_name?: string;
    items: Product[];
  };
}

export interface ProductDetailViewInterface {
  items: PricedProduct[];
  cheapestVariant?: CalculatedVariant;
  selectedVariant?: CalculatedVariant;
  currency: string;
  eventBeforeSend?: ProductEventData;
}

export type Brand = {
  name: string;
  image?: string;
};

export interface CartModifyInterface {
  product: PricedProduct;
  variant: PricedVariant;
  categoryPath?: string[];
  region: Region;
}

export interface CartModifyEventInterface {
  product: PricedProduct;
  variant: PricedVariant;
  categoryPath?: string[];
  region: Region;
}

export interface CheckoutStepEventInterface {
  step: number;
  cart: Cart;
}

export interface PurchaseEventInterface {
  order: Order;
  cart: Omit<Cart, "refundable_amount" | "refunded_total">;
  customer: Omit<Customer, "password_hash">;
}

export interface CheckoutStepInterface {
  item: LineItem;
}

export interface CheckoutStepEventData extends BasicEvent {
  ecommerce: {
    checkout: {
      actionField: {
        step: number;
      };
      prodNumber: number;
      products: CheckoutStepProduct[];
    };
  };
}

export interface PurchaseEventData extends BasicEvent {
  ecommerce: {
    currencyCode: "EUR";
    purchase: {
      actionField: {
        id: number;
        affiliation: string;
        revenue: string;
        tax: string;
        shipping: string;
        metric2: string;
        dimension4: string;
        dimension5: string;
        coupon?: string;
        customerFirstName: string;
        customerLastName: string;
        customerEmail: string;
      };
      prodNumber: number;
      products: CheckoutStepProduct[];
    };
  };
}

type GtmEvent = ProductEventData | CartModifyEventData | CheckoutStepEventData | PurchaseEventData;

function hasValidItemId(item: ProductPreviewType | CalculatedVariant | PricedProduct): item is ProductPreviewType & { id: string } {
  return typeof (item.metadata?.sku ?? item.id) === "string";
}

function hasValidSku(item: ProductPreviewType): item is ProductPreviewType & { metadata: { sku: string } } {
  return typeof item.metadata?.sku === "string";
}

function convertCategoryPath(categoryPath: string[]): { [key: string]: string } {
  return categoryPath.slice(0, 5).reduce(
    (acc, value, index) => {
      const key = index === 0 ? "item_category" : `item_category${index + 1}`;
      acc[key] = value;
      return acc;
    },
    {} as { [key: string]: string },
  );
}

function sendEvent(event: GtmEvent) {
  sendGTMEvent(event);
  if (process.env.GOOGLE_DATA_LAYER_DEBUG === "true") {
    console.log("GTM Event", event);
  }
}

export const productListViewEvent = ({ categoryPath, itemListId, itemListName, items, eventBeforeSend, skipFirst = 0 }: ProductListView) => {
  const preparedProducts = preparedPreviewProductsData({
    items: items,
    categoryPath: categoryPath,
    itemListId: itemListId,
    itemListName: itemListName,
    skipFirst: skipFirst,
  });

  if (preparedProducts.length) {
    const eventData: ProductEventData = {
      event: "view_item_list",
      ecommerce: {
        item_list_id: _.snakeCase(itemListId),
        item_list_name: itemListName,
        items: preparedProducts,
      },
    };

    if (eventBeforeSend === eventData) {
      return undefined;
    }

    sendGTMEvent({ ecommerce: null });
    sendEvent(eventData);
    return eventData;
  }
  return undefined;
};

interface PreparedProductsPreviewDataParams {
  items: (ProductPreviewType | CalculatedVariant | PricedProduct)[];
  categoryPath?: string[];
  itemListId?: string;
  itemListName?: string;
  index?: number;
  cheapestVariant?: CalculatedVariant;
  selectedVariant?: CalculatedVariant;
  skipFirst?: number;
}

export interface ProductDetailViewEventData extends BasicEvent {
  ecommerce: {
    currency: string;
    value: number;
    items: Product[];
  };
}

export interface CartModifyEventData extends BasicEvent {
  ecommerce: {
    add?: {
      products: CartModifyProduct;
    };
    remove?: {
      products: CartModifyProduct;
    };
  };
}

function selectPrices(item: ProductPreviewType, selectedVariant: CalculatedVariant | undefined, cheapestVariant: CalculatedVariant | undefined) {
  let priceDifference = item.price?.difference_price;
  let calculatedPrice = item.price?.calculated_price;
  const originalPrice = item.price?.original_price;

  if (!calculatedPrice && selectedVariant) {
    priceDifference = parseFloat(((selectedVariant.original_price - selectedVariant.calculated_price) / 100).toFixed(2));
    calculatedPrice = (selectedVariant.calculated_price / 100).toFixed(2).toString();
  }

  if (!calculatedPrice && cheapestVariant) {
    priceDifference = parseFloat(((cheapestVariant.original_price - cheapestVariant.calculated_price) / 100).toFixed(2));
    calculatedPrice = (cheapestVariant.calculated_price / 100).toFixed(2).toString();
  }

  return { priceDifference, calculatedPrice, originalPrice };
}

function selectItemId(item: ProductPreviewType, selectedVariant: CalculatedVariant | undefined, cheapestVariant: CalculatedVariant | undefined): string {
  let itemId = hasValidSku(item) ? item.metadata?.sku : undefined;

  if (!itemId && selectedVariant) {
    itemId = selectedVariant.sku ?? undefined;
  }

  if (!itemId && cheapestVariant) {
    itemId = cheapestVariant.sku ?? undefined;
  }

  return itemId ?? item.id ?? "nicht ermittelt";
}

function selectVariantOptions(item: ProductPreviewType, selectedVariant: CalculatedVariant | undefined, cheapestVariant: CalculatedVariant | undefined) {
  let variantOptions = item.preferred_variant
    ? item.variants
        .find((v) => v.id === item.preferred_variant)
        ?.options?.map((o) => o.value)
        .sort()
    : undefined;

  if (!variantOptions && selectedVariant) {
    variantOptions = item.variants
      .find((v) => v.id === selectedVariant.id)
      ?.options?.map((o) => o.value)
      .sort();
  }

  if (!variantOptions && cheapestVariant) {
    variantOptions = item.variants
      .find((v) => v.id === cheapestVariant.id)
      ?.options?.map((o) => o.value)
      .sort();
  }
  return variantOptions;
}

function preparedPreviewProductsData({
  items,
  categoryPath,
  itemListId,
  itemListName,
  index,
  cheapestVariant,
  selectedVariant,
  skipFirst = 0,
}: PreparedProductsPreviewDataParams) {
  const preparedProducts: Product[] = [];
  for (let position = skipFirst; position < items.length; position++) {
    const item = items[position];
    if (!hasValidItemId(item)) {
      continue;
    }

    const categories = categoryPath ? convertCategoryPath(categoryPath) : null;
    const { priceDifference, calculatedPrice } = selectPrices(item, selectedVariant, cheapestVariant);
    const discount = priceDifference ?? 0;
    const itemId = selectItemId(item, selectedVariant, cheapestVariant);
    const variantOptions = selectVariantOptions(item, selectedVariant, cheapestVariant);
    const variant = variantOptions?.join(", ") ?? "";

    const product: Product = {
      affiliation: "Channel21.de",
      discount: discount,
      index: index ?? position,
      item_id: itemId,
      item_list_id: _.snakeCase(itemListId),
      item_list_name: itemListName,
      item_name: item.title ?? "",
      item_variant: variant ?? "",
      item_category: item.categories?.[0]?.name,
      price: parseFloat(calculatedPrice ?? "0"),
      quantity: 1,
    };

    if ((item.metadata?.brand as Brand).name) {
      product.item_brand = (item.metadata?.brand as Brand).name;
    }

    if (item.metadata?.product_type_2) {
      product.item_category2 = item.metadata?.product_type_2 as string;
    }

    preparedProducts.push({ ...product, ...categories });
  }

  return preparedProducts;
}

function preparedCartModifyProductData({ product, variant }: CartModifyInterface) {
  const categoryPath = product.categories?.[0]?.handle.split("/");
  const categories = categoryPath ? convertCategoryPath(categoryPath) : null;

  const itemId = (product.metadata?.sku as string) ?? product.id;
  const categoryPathString = categories ? Object.values(categories).join("/") : undefined;

  const data: CartModifyProduct = {
    name: product.title ?? "",
    id: itemId ?? "",
    quantity: 1,
    price: parseFloat(((variant?.original_price ?? 0) / 100).toFixed(2)),
    variant: (variant.metadata?.filter_color as string) ?? undefined,
    dimension1: (variant.metadata?.filter_size as string) ?? undefined,
    category: product.categories?.[0]?.name,
    dimension2: variant.ean ?? null,
    dimension3: variant.sku ?? null,
    metric1: parseFloat(((variant?.calculated_price ?? 0) / 100).toFixed(2)),
  };

  if ("string" === typeof product.categories?.[0]?.name) {
    data.category = product.categories?.[0]?.name; // deepest category
  }

  if ("string" === typeof categoryPathString) {
    data.dimension7 = categoryPathString;
  }
  if ((product.metadata?.brand as Brand).name) {
    data.brand = (product.metadata?.brand as Brand).name;
  }

  return data;
}

function preparedCheckoutStepProductData({ item }: CheckoutStepInterface) {
  const variant = item.variant;
  const product = variant.product;
  if (!variant || !product) {
    return null;
  }
  const variantPrice = "string" === typeof variant.metadata?.original_price ? parseFloat(variant.metadata?.original_price) : 0;

  const data: CheckoutStepProduct = {
    name: product.title ?? "",
    id: (product.metadata?.sku as string) ?? product.id,
    quantity: item.quantity,
    price: parseFloat(variantPrice.toFixed(2)),
    variant: (variant.metadata?.filter_color as string) ?? undefined,
    dimension1: (variant.metadata?.filter_size as string) ?? undefined,
    dimension2: variant.ean ?? null,
    dimension3: variant.sku,
    metric1: parseFloat((item.unit_price / 100).toFixed(2)),
    category: product.categories?.[0]?.name,
  };

  if ((product.metadata?.brand as Brand).name) {
    data.brand = (product.metadata?.brand as Brand).name;
  }

  return data;
}

export const productCardClickEvent = ({ categoryPath, itemListId, itemListName, items, index }: ProductCardClick) => {
  const preparedProducts = preparedPreviewProductsData({
    items: items,
    categoryPath: categoryPath,
    itemListId: itemListId,
    itemListName: itemListName,
    index: index,
  });

  if (preparedProducts.length) {
    const eventData: ProductEventData = {
      event: "select_item",
      ecommerce: {
        item_list_id: _.snakeCase(itemListId),
        item_list_name: itemListName,
        items: preparedProducts,
      },
    };
    sendGTMEvent({ ecommerce: null });
    sendEvent(eventData);
  }
  return undefined;
};

export const productDetailViewEvent = ({ items, currency, cheapestVariant, selectedVariant }: ProductDetailViewInterface) => {
  const preparedProducts = preparedPreviewProductsData({ items: items, cheapestVariant: cheapestVariant, selectedVariant: selectedVariant });

  if (preparedProducts.length) {
    const eventData: ProductDetailViewEventData = {
      event: "view_item",
      ecommerce: {
        currency: currency.toUpperCase(),
        value: preparedProducts.reduce((acc, item) => acc + item.price, 0),
        items: preparedProducts,
      },
    };

    return eventData;
  }
  return undefined;
};

export const addToCartEvent = ({ product, variant, region }: CartModifyEventInterface) => {
  const preparedProducts = preparedCartModifyProductData({ product: product, variant: variant, region: region });

  const eventData: CartModifyEventData = {
    event: "addToCart",
    ecommerce: {
      add: {
        products: { ...preparedProducts },
      },
    },
  };

  sendGTMEvent({ ecommerce: null });
  sendEvent(eventData);
};

export const removeFromCartEvent = ({ product, variant, region }: CartModifyEventInterface) => {
  const preparedProducts = preparedCartModifyProductData({ product: product, variant: variant, region: region });

  const eventData: CartModifyEventData = {
    event: "removeFromCart",
    ecommerce: {
      remove: {
        products: { ...preparedProducts },
      },
    },
  };

  sendGTMEvent({ ecommerce: null });
  sendEvent(eventData);
};

export const checkoutStepEvent = ({ step, cart }: CheckoutStepEventInterface) => {
  const preparedProducts: CheckoutStepProduct[] = [];
  cart.items.map((item) => {
    const preparedData = preparedCheckoutStepProductData({ item });
    if (!preparedData) {
      return;
    }
    preparedProducts.push(preparedData);
  });

  const eventData: CheckoutStepEventData = {
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: step,
        },
        prodNumber: cart.items.length,
        products: preparedProducts,
      },
    },
  };

  sendGTMEvent({ ecommerce: null });
  sendEvent(eventData);
};

export const purchaseEvent = ({ order, cart, customer }: PurchaseEventInterface) => {
  const preparedProducts: CheckoutStepProduct[] = [];
  const shippingTotal = cart.shipping_total ?? 0;
  // const subTotal = cart.subtotal ?? 0;
  const taxValue = (cart.tax_total ?? 100) / 100;
  const tax = taxValue.toFixed(2);
  const shipping = shippingTotal + (cart.shipping_tax_total ?? 0);
  // const revenue = ((subTotal + shipping + taxValue) / 100).toFixed(2); // Brutto-Bestellwert mit Steuern, Versandkosten - ohne Gutscheinwert, Rabatte
  //const netValue = ((subTotal + shipping - taxValue) / 100).toFixed(2); // Netto-Wert des Bestellpreises (Brutto-Bestellwert minus MwSt)
  let revenue = 0;
  let netValue = 0;

  cart.items.map((item) => {
    const preparedData = preparedCheckoutStepProductData({ item });
    if (!preparedData) {
      return;
    }
    const itemTax = item.includes_tax && item.tax_total ? item.tax_total : 0;
    preparedProducts.push(preparedData);
    revenue += (item.unit_price * item.quantity) / 100; // Berechnen wir selber neu, da am order/cart nur Gutschein-Rabattierte Werte gespeichert sind
    netValue += ((item.unit_price - itemTax) * item.quantity) / 100;
  });

  revenue += shipping / 100;
  revenue = parseFloat(revenue.toFixed(2));

  netValue += shippingTotal / 100;

  const eventData: PurchaseEventData = {
    event: "purchase",
    ecommerce: {
      currencyCode: "EUR",
      purchase: {
        actionField: {
          id: order.display_id,
          affiliation: "c21",
          revenue: revenue.toFixed(2), // Berechnen wir selber, da am order/cart nur Gutschein-Rabattierte Werte gespeichert sind
          tax,
          shipping: (shipping / 100).toFixed(2),
          metric2: netValue.toFixed(2),
          dimension4: order.payments.at(0)?.provider_id ?? "",
          dimension5: customer.has_account ? "Angemeldet" : "Gast",
          customerFirstName: customer.first_name ?? order.billing_address.first_name ?? "",
          customerLastName: customer.last_name ?? order.billing_address.last_name ?? "",
          customerEmail: customer?.email ?? "",
        },
        prodNumber: order.items.length,
        products: preparedProducts,
      },
    },
  };

  if ("undefined" !== typeof order.discounts?.[0]?.code) {
    eventData.ecommerce.purchase.actionField.coupon = order.discounts[0].code;
  }

  sendGTMEvent({ ecommerce: null });
  sendEvent(eventData);
};
