"use client";

import { Region } from "@medusajs/medusa";
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import { formatAmount, useCart, useProducts } from "medusa-react";
import { useEffect, useMemo, useState } from "react";

import { useStore } from "@/lib/contexts/store-context";
import { getPercentageDiff } from "@/lib/util/get-precentage-diff";
import { CalculatedVariant } from "@/types/medusa";

type useProductPriceProps = {
  id?: string;
  product?: PricedProduct;
  variantId?: string;
};

export const useProductPriceFormat = ({ region }: { region: Region }) => {
  const { locale } = useStore();

  const output = (amount?: number | undefined | null) => {
    if (!amount) {
      return `0 €`;
    }

    return formatAmount({
      amount: amount,
      region: region,
      includeTaxes: false,
      locale: locale,
    });
  };

  return { output };
};

const isPricedProduct = (product: PricedProduct | null | undefined): product is PricedProduct => {
  if (!product) {
    return false;
  }

  return !(!product.variants || !product.variants.length || !product.variants[0].prices);
};

const variantPriceResult = ({ variant, region, locale }: { variant: CalculatedVariant; region: Region; locale: string }) => {
  const best_price = variant.prices.find((p) => p.id === "best-price");
  return {
    calculated_price: formatAmount({
      amount: variant.calculated_price,
      region: region,
      includeTaxes: false,
      locale: locale,
    }),
    original_price: formatAmount({
      amount: variant.original_price,
      region: region,
      includeTaxes: false,
      locale: locale,
    }),
    best_price: best_price
      ? formatAmount({
          amount: best_price.amount,
          region: region,
          includeTaxes: false,
          locale: locale,
        })
      : undefined,
    price_type: variant.calculated_price_type,
    percentage_diff: getPercentageDiff(variant.original_price, variant.calculated_price),
  };
};

const useProductPrice = ({ id, variantId, ...props }: useProductPriceProps) => {
  const { cart } = useCart();
  const { locale } = useStore();
  const [product, setProduct] = useState<PricedProduct | null>(isPricedProduct(props.product) ? props.product : null);

  const { products, isLoading, isError } = useProducts(
    { id: [id ?? (props.product?.id ? props.product.id : "")], cart_id: cart?.id, limit: 1 },
    // Only fetch if the cart is loaded and the id is set,
    // this is to prevent fetching the product before the cart is loaded.
    // Also, only fetch if the product is not already set and only fetch if the product is not priced.
    {
      enabled: !!cart?.id && !!cart?.region_id && !!id && (!product || !isPricedProduct(product)),
      initialData: () => {
        return isPricedProduct(product)
          ? {
              products: [product],
              limit: 100,
              offset: 0,
              count: 1,
              response: {
                headers: {},
                config: {},
                status: 200,
                statusText: "OK",
              },
            }
          : undefined;
      },
    },
  );

  useEffect(() => {
    // when the product is fetched, set the product state
    setProduct(products?.[0] ?? null);
  }, [products]);

  const [cheapestVariant, cheapestPrice] = useMemo(() => {
    if (!product || !product.variants?.length || !cart?.region) {
      return [null, null];
    }

    const variants = product.variants as unknown as CalculatedVariant[];

    const cheapestVariant = variants.reduce((prev, curr) => {
      return prev.calculated_price < curr.calculated_price ? prev : curr;
    });

    return [cheapestVariant, variantPriceResult({ variant: cheapestVariant, region: cart.region, locale })];
  }, [product, cart?.region, locale]);

  const variant = useMemo(() => product?.variants.find((v) => v.id === variantId || v.sku === variantId) as unknown as CalculatedVariant, [product, variantId]);

  const variantPrice = useMemo(() => {
    if (!product || !variant || !cart?.region) {
      return null;
    }

    return variantPriceResult({ variant, region: cart.region, locale });
  }, [product, variant, cart?.region, locale]);

  return {
    product,
    cheapestVariant,
    cheapestPrice,
    variantPrice,
    variant,
    isLoading,
    isError,
  };
};

export default useProductPrice;
