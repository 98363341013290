import { computeVariantPrice, formatAmount, useCart } from "medusa-react";

import { useStore } from "../../lib/contexts/store-context";
import { Variant } from "../../types/medusa";

const purifiedContentUnit = (contentUnit: string) => {
  switch (contentUnit) {
    case "cm":
      return { multiplier: 100, unit: "m", unitAmount: 1 };
    case "ml":
      return { multiplier: 1000, unit: "L", unitAmount: 1 };
    case "g":
      return { multiplier: 1000, unit: "kg", unitAmount: 1 };
    default:
      return { multiplier: 1, unit: "", unitAmount: 1 };
  }
};

export const useBasePrice = () => {
  const { cart, createCart } = useCart();
  const { locale } = useStore();
  if (!cart || !cart.region || createCart.isLoading) {
    return () => "0";
  }

  return (variant: Variant, actualPrice?: number) => {
    const content = variant.metadata?.content;
    const contentUnit = variant.metadata?.content_unit;

    if (!content || typeof content !== "string" || !contentUnit || typeof contentUnit !== "string") {
      return "";
    }
    const purifiedContent = content.match(/\d+(\.\d+)?/);
    if (!purifiedContent) {
      return "";
    }

    const numericContent = parseFloat(purifiedContent[0]);
    if (actualPrice === undefined) {
      actualPrice = computeVariantPrice({
        variant,
        region: cart.region,
      });
    }
    const { multiplier, unitAmount, unit } = purifiedContentUnit(contentUnit);

    const basePrice = ((actualPrice ?? 0) / numericContent) * multiplier;

    if (!basePrice) {
      return "";
    }

    const formattedPrice = formatAmount({
      amount: basePrice,
      region: cart.region,
      includeTaxes: false,
      locale,
    });

    return `Grundpreis: ${formattedPrice} / ${unitAmount.toLocaleString()} ${unit}. `;
  };
};
