import React from "react";
import Skeleton from "react-loading-skeleton";

interface Props {
  count?: number;
  className?: string;
}
export default function ProductCardSkeleton({ count = 1, className }: Props) {
  return (
    <>
      {Array.from({ length: count }).map((_, i) => (
        <div key={i} data-testid={`ProductCardSkeleton-${i}`} className={className ?? "rounded col-span-3 md:col-span-1"}>
          <Skeleton width={"100%"} className={"aspect-[1/1.3]"} />
          <div className={"py-2.5"}>&zwnj;</div>
          <Skeleton width="20%" height={20} className={"mb-2.5"} />
          <Skeleton count={2} width="100%" height={20} className={"mb-2"} />
          <Skeleton width="50%" height={23} />
          <Skeleton width="55%" height={12} />
          <Skeleton width="60%" height={15} />
        </div>
      ))}
    </>
  );
}

export const CheckoutProductCardSkeleton = () => {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-3 lg:col-span-2">
        <Skeleton width={"100%"} height={"8rem"} />
      </div>
      <div className="col-span-9 lg:col-span-10 pl-7">
        <Skeleton width={"15rem"} height={"1.25rem"} className="mb-3" />
        <Skeleton count={2} width={"6rem"} height={".75rem"} className="m-0" />
        <Skeleton width={"7.5rem"} height={".75rem"} className="mb-3" />

        <Skeleton width={"5rem"} height={"1rem"} className="mb-1" />
        <Skeleton width={"10rem"} height={".75rem"} />
      </div>
    </div>
  );
};
